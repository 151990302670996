<template>
  <div>
    <v-btn @click="(dialogCreateRequest = true), SET_BORROWERS([])">
      <v-icon>add</v-icon>
    </v-btn>

    <!-- Dialog create request -->
    <DialogBox :model="dialogCreateRequest">
      <template slot="toolbar">
        <v-icon v-if="newBorrower" @click="newBorrower = false" color="white" class="mr-1"> arrow_back </v-icon>
        <span>Crear solicitud</span>
        <v-spacer />
        <v-icon v-if="searcher.posOfListSelected === null && !newBorrower" @click="newBorrower = true">person_add</v-icon>
      </template>
      <template slot="content">
        <v-form ref="formCreateRequest" v-model="formCreateRequestValid" @submit.prevent="">
          <!-- Searcher of borrowers -->
          <div v-if="!newBorrower">
            <v-text-field
              v-model="searcher.search"
              label="Buscar prestataria"
              outlined
              append-icon="send"
              :rules="formRules.textRules"
              @input="request.identity_document = searcher.search"
              @click:append="search()"
              @keydown.enter="search()"
            />

            <v-list v-if="borrowers.length > 0" dense class="secondary pa-2">
              <v-list-item-group v-model="searcher.posOfListSelected" color="primary" @change="checkBorrowerCreditHistory()">
                <v-subheader>Resultados de la búsqueda:</v-subheader>
                <v-list-item v-for="(borrower, i) in borrowers" :key="i">
                  <v-list-item-icon>
                    <v-icon>nature_people</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ borrower.identity_document }} - {{ borrower.full_name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <div v-if="creditHistory.length > 0" class="mt-2">
              <div v-if="creditHistory.some((e) => !e.allowed)" class="red pa-3">
                <p class="text-subtitle-1">No se puede crear una solicitud a esta prestataria debido a:</p>
                <ul>
                  <li v-for="(element, i) in creditHistory.filter((e) => !e.allowed)" :key="i" class="mb-0">
                    <router-link :to="`/${element.type === 'project' ? 'proyectos' : 'solicitudes'}/${element.id}`" target="_blank©">
                      {{ element.id }}
                    </router-link>
                    - {{ element.status }}
                  </li>
                </ul>
              </div>
              <div v-else class="orange pa-3">
                <p class="text-subtitle-1">Tener en cuenta los siguientes proyectos/solicitudes:</p>
                <ul>
                  <li v-for="(element, i) in creditHistory.filter((e) => e.allowed)" :key="i" class="mb-0">
                    <router-link :to="`/${element.type === 'project' ? 'proyectos' : 'solicitudes'}/${element.id}`" target="_blank©">
                      {{ element.id }}
                    </router-link>
                    - {{ element.status }}
                  </li>
                </ul>
              </div>
            </div>

            <div v-if="!creditHistory.some((e) => !e.allowed) || user.role === 'SUPERADMIN' || user.role === 'SUPERTECHNICIAN'">
              <TechnicianAutocomplete
                v-if="searcher.posOfListSelected !== null"
                :technicianId="searcher.technician_id"
                :filter="{ 'country.id': borrowerSelected.country.id }"
                :editable="true"
                @selected="(searcher.technician_id = $event), getTechnicianSelectedAndHisOffices($event)"
              />
              <v-select
                v-if="searcher.technician_id && technicianSelected.role === 'SUPERTECHNICIAN'"
                v-model="searcher.office_id"
                :items="offices"
                item-text="name"
                item-value="id"
                label="Oficina"
              />
            </div>
          </div>

          <!-- New borrower -->
          <div v-if="newBorrower">
            <p class="font-weight-black mt-3">1.0 DATOS DE LA SOLICITANTE</p>
            <v-text-field v-model="request.first_name" label="Nombre" :rules="formRules.textRules" />
            <v-text-field v-model="request.last_name" label="Apellidos" :rules="formRules.textRules" />
            <v-text-field v-model="request.identity_document" label="Número documento de identidad" :rules="formRules.textRules" />
            <v-text-field v-model="request.email" type="email" label="Email" :rules="formRules.emailRules" />
            <v-text-field v-model="request.phone" label="Nº de teléfono" :rules="formRules.phoneRules" />
            <v-text-field v-model="request.address.name" label="Dirección del domicilio" :rules="formRules.textRules" />
            <CountryAutocomplete :countryId="request.country" :editable="true" @selected="handleCountrySelection($event)" />
            <v-autocomplete
              v-if="!_.isEmpty(request.country)"
              v-model="request.city"
              :items="cities"
              item-text="name"
              item-value="id"
              label="Ciudad"
              :rules="formRules.textRules"
            />
            <v-text-field v-if="!_.isEmpty(request.city)" v-model="request.zone" label="Zona" :rules="formRules.textRules" />
            <DatePicker label="Fecha de nacimiento" :type_birthdate="true" :required="true" @change="request.date_of_birth = $event" />
            <TechnicianAutocomplete
              v-if="!_.isEmpty(request.country)"
              :technicianId="request.technician_id"
              :filter="{ 'country.id': request.country }"
              :editable="true"
              @selected="(request.technician_id = $event), getTechnicianSelectedAndHisOffices($event)"
            />
            <v-select
              v-if="request.technician_id && technicianSelected.role === 'SUPERTECHNICIAN'"
              v-model="request.office_id"
              :items="offices"
              item-text="name"
              item-value="id"
              label="Oficina"
            />
          </div>
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('formCreateRequest')">Cerrar</v-btn>
        <v-btn
          v-if="searcher.posOfListSelected !== null || newBorrower"
          text
          :disabled="creditHistory.some((e) => !e.allowed) && user.role !== 'SUPERADMIN' && user.role !== 'SUPERTECHNICIAN'"
          @click="create()"
        >
          Crear
        </v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import _ from "lodash";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import DialogBox from "@/components/elements/DialogBox";
import DatePicker from "@/components/elements/DatePicker";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete";
import TechnicianAutocomplete from "@/components/elements/technician/TechnicianAutocomplete";

export default {
  components: {
    DialogBox,
    DatePicker,
    CountryAutocomplete,
    TechnicianAutocomplete
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  data() {
    return {
      // Btns before create request
      newBorrower: false,

      // Fields of request
      request: {
        first_name: "",
        last_name: "",
        identity_document: "",
        email: "",
        phone: "",
        address: {
          coordinates: {
            lat: 0,
            lon: 0
          },
          name: ""
        },
        country: "",
        city: "",
        zone: "",
        date_of_birth: "",
        technician_id: ""
      },

      // Variable of borrower selected
      borrowerSelected: {},

      // Variable of technician selected
      technicianSelected: {},

      // Searcher fields
      searcher: {
        search: "",
        posOfListSelected: null,
        technician_id: ""
      },
      creditHistory: [],

      // Dialogs
      dialogCreateRequest: false,
      formCreateRequestValid: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      borrowers: (state) => state.borrowers.borrowers,
      technicians: (state) => state.technicians.technicians,
      offices: (state) => state.offices.offices,
      cities: (state) => state.locations.cities
    }),
    _() {
      return _;
    }
  },
  methods: {
    ...mapActions({
      createBorrower: "borrowers/createBorrower",
      createRequest: "requests/createRequest"
    }),
    ...mapMutations({
      SET_BORROWERS: "borrowers/SET_BORROWERS"
    }),

    // Create request
    async create() {
      if (this.$refs.formCreateRequest.validate()) {
        if (this.newBorrower) {
          const requestFormattedData = { ...this.request };
          requestFormattedData.date_of_birth = this.formatDateToISO8601(this.request.date_of_birth);

          const office_id = requestFormattedData.office_id;
          delete requestFormattedData.technician_id;
          delete requestFormattedData.office_id;
          const borrower_id = await this.createBorrower(requestFormattedData);
          console.log(requestFormattedData);
          if (this.technicianSelected.role === "TECHNICIAN") {
            await this.createRequest({ borrower_id, technician_id: this.technicianSelected.id });
          } else await this.createRequest({ borrower_id, technician_id: this.technicianSelected.id, office_id });
        } else {
          if (this.searcher.posOfListSelected !== null) {
            const data = {
              borrower_id: this.borrowers[this.searcher.posOfListSelected].id,
              technician_id: this.searcher.technician_id
            };
            if (this.technicianSelected.role === "SUPERTECHNICIAN") data.office_id = this.searcher.office_id;
            await this.createRequest(data);
            this.closeDialog("formCreateRequest");
          }
        }

        await this.$store.dispatch("requests/getRequests", { size: 10, sort: { created_at: "DESC" } });
        this.closeDialog("formCreateRequest");
      }
    },

    // Borrower searcher
    async search() {
      if (this.$refs.formCreateRequest.validate()) {
        await this.$store.dispatch("borrowers/getBorrowers", { query: this.searcher.search });
        this.searcher.posOfListSelected = null;
        this.creditHistory = this.$options.data().creditHistory;
      }
    },

    // Check if request can be create
    async checkBorrowerCreditHistory() {
      this.borrowerSelected = this.borrowers[this.searcher.posOfListSelected];
      this.creditHistory = await this.$store.dispatch("requests/checkBorrowerCreditHistory", this.borrowerSelected.id);
    },

    async getTechnicianSelectedAndHisOffices(technicianId) {
      this.technicianSelected = await this.$store.dispatch("technicians/getTechnician", { filter: { id: technicianId } });
      if (this.technicianSelected.role === "SUPERTECHNICIAN")
        await this.$store.dispatch("offices/getOffices", { filter: { "partner.id": this.technicianSelected.partner.id } });
    },

    async handleCountrySelection(countryId) {
      await this.$store.dispatch("locations/getCities", { filter: { country: countryId } });
      this.request.city = null;
      this.request.technician_id = null;
      this.request.country = countryId;
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(formRef) {
      this.$refs[formRef].resetValidation();
      Object.assign(this.$data, this.$options.data());
    }
  }
};
</script>
