<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Solicitudes'">
      <template v-slot:actions>
        <BtnCreateRequest v-if="permissionsBannerActions.create.includes(user.role)" />
        <v-divider vertical inset class="mx-4" />
        <v-btn small @click="downloadAllRequestsReport()">
          <v-img :src="require(`@/assets/icons/download_pdf.png`)" max-width="24" class="mr-2" />
          Reporte de solicitudes
        </v-btn>
      </template>
    </Banner>

    <!-- Data table requests -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="$store.state.requests.requests"
        :totalItems="$store.state.requests.totalCountRequests"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getRequests"
      >
        <template v-slot:filters>
          <RequestsFilters :elasticData="elasticData" @getRequests="getFilteredRequests" />
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <router-link v-if="item.borrower" :to="`/solicitudes/${item.id}`">{{ item.id }}</router-link>
          <span v-else>{{ item.id }}</span>
        </template>
        <template v-slot:[`item.borrower`]="{ item }">
          <router-link v-if="item.borrower" :to="`/prestatarias/${item.borrower.id}`">{{ item.borrower.name }}</router-link>
          <span v-else>Sin prestataria</span>
        </template>
        <template v-slot:[`item.technician`]="{ item }">
          <router-link v-if="item.technician" :to="`/tecnicos/${item.technician.id}`">{{ item.technician.name }}</router-link>
          <span v-else>Sin técnico</span>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import Banner from "@/components/elements/Banner";
import RequestsFilters from "@/components/elements/request/RequestsFilters";
import DataTable from "@/components/elements/DataTable";
import BtnCreateRequest from "@/components/elements/request/BtnCreateRequest";

export default {
  name: "Requests",
  components: {
    Banner,
    DataTable,
    BtnCreateRequest,
    RequestsFilters
  },
  mixins: [FormatDateMixin],
  data() {
    return {
      // Banner actions
      permissionsBannerActions: {
        create: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN", "TECHNICIAN"]
      },

      // Requests datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Prestataria", value: "borrower", sortable: false },
        { text: "Técnico", value: "technician", sortable: false },
        { text: "Estado", value: "status", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ],

      // Filters variables
      elasticData: {}
    };
  },
  computed: {
    ...mapState("user", ["user", "dbUser"])
  },
  async created() {
    await this.$store.dispatch("user/getDbUserInfo");
    if (this.user.role === "TECHNICIAN") {
      await this.getRequests({ size: 10, sort: { created_at: "DESC" }, filter: { "technician.id": this.user.user_id } });
      this.elasticData = { size: 10, sort: { created_at: "DESC" }, filter: { "technician.id": this.user.user_id } };
    } else if (this.user.role === "SUPERTECHNICIAN") {
      await this.getRequests({ size: 10, sort: { created_at: "DESC" }, filter: { "partner.id": this.dbUser.technician.partner_id } });
      this.elasticData = { size: 10, sort: { created_at: "DESC" }, filter: { "partner.id": this.dbUser.technician.partner_id } };
    } else await this.getRequests({ size: 10, sort: { created_at: "DESC" } });
  },
  methods: {
    ...mapActions({
      getRequests: "requests/getRequests"
    }),

    async handlePrevIcon(elasticData) {
      if (this.user.role === "TECHNICIAN") elasticData.filter = { "technician.id": this.user.user_id };
      this.elasticData = elasticData;
      await this.getRequests(elasticData);
    },

    async handleNextIcon(elasticData) {
      if (this.user.role === "TECHNICIAN") elasticData.filter = { "technician.id": this.user.user_id };
      this.elasticData = elasticData;
      await this.getRequests(elasticData);
    },

    async handleSearch(elasticData) {
      if (this.user.role === "TECHNICIAN") elasticData.filter = { "technician.id": this.user.user_id };
      this.elasticData = elasticData;
      await this.getRequests(elasticData);
    },

    async getFilteredRequests(elasticData) {
      if (this.user.role === "TECHNICIAN") elasticData.filter = { "technician.id": this.user.user_id };
      await this.getRequests(elasticData);
    },

    // Download all borrowers report
    async downloadAllRequestsReport() {
      const { data: path } = await this.$store.dispatch("requests/downloadAllRequestsReport");
      window.open(path, "_blank");
    }
  }
};
</script>
